import React from "react";

class Popup extends React.Component {
  handleClick() {
    this.props.togglePopup();
  }
  render() {
    return (
      <div className="innerPopupDiv">
        <p className="montserrat bold regMaroonText ">WINTER QUARTER 2022</p>
        <p>
          CSIL will be offering technical support by way of a remote help desk
          for the first two weeks of the quarter. You can access the remote desk{" "}
          <a
            className="lightRedLink"
            href="https://uchicago.zoom.us/j/97202570589?pwd=cUp4Q3hmQ3c0U3JNZGtUTGlDSWtEQT09#success"
            rel="noopener noreferrer"
            target="_blank"
          >
            here
          </a>
          .
          <br />
          <br />
          The hours of operation will be: <br />
          Monday - Friday 10am - 6pm CST (4pm - 12am UTC) <br />
          Saturday - Sunday 12pm - 4pm CST (6pm - 10pm UTC)
          <br />
          <br />
          Please contact csil[at]cs.uchicago.edu with any questions.
        </p>
        <button className="redButton" onClick={() => this.handleClick()}>
          Acknowledge
        </button>
      </div>
    );
  }
}

export default Popup;
