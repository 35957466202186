import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Twirl as Hamburger } from "hamburger-react";
import "./Navigation.css";
import navIconImg from "../images/logo_black.png";
import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from "react-dom/cjs/react-dom.production.min";

class Navigation extends React.Component {
  state = {
    menuIsOpen: false,
  };

  handleMenu() {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
  }
  handleClickOutside = (event) => {
    if (this.state.menuIsOpen && !this.navContainer.contains(event.target)) {
      this.setState({ menuIsOpen: false });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
        <div class = "navContainer"
        ref={(node) => {
            this.navContainer = node;
          }}>
        <nav style = {{backgroundColor: this.state.menuIsOpen? "" : "white"}}>
        <Link to="/" className="navIconDiv">
          <img src={navIconImg} alt="csil logo" className="navIcon" style = {{WebkitFilter:  this.state.menuIsOpen? "grayscale(1) invert(1)" : "none",
    filter: this.state.menuIsOpen? "grayscale(1) invert(1)" : "none"}}/>
        </Link>
        <div
          className="hamDiv"
        >
          <Hamburger
            color={this.state.menuIsOpen ? "white" : "#000000"}
            toggled={this.state.menuIsOpen}
            className="hamMenu"
            onToggle={() => this.handleMenu()}
          />
        </div>
        </nav>
        <div className={`navMain ${this.state.menuIsOpen? "visible" : ""} `} style={{
              height: this.state.menuIsOpen ? "fit-content" : "0%",
              opacity: this.state.menuIsOpen ? .9 : 0,
            }}
          >
            <div className="navPopMain rowWithMarginWrap" style = {{height:this.state.menuIsOpen? "auto" : "0"}}>
              <div className="quadMenu colNoCenter" >
                <h2>General</h2>

                <Link
                  to="/"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline"> Home</span>
                </Link>
                <Link
                  to="/about"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline"> About Us</span>
                </Link>
                <Link
                  to="/staff"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline"> Staff</span>
                </Link>
                <Link
                  to="/policies"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline"> Policies</span>
                </Link>
              </div>
              <div className="quadMenu colNoCenter">
                <h2>Services</h2>

                <Link
                  to="/software"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline">
                    Available Software
                  </span>
                </Link>
                <Link
                  to="/hardware"
                  href="https://www.lib.uchicago.edu/crerar/"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline">
                    Available Hardware
                  </span>
                </Link>
                <Link
                  to="/minicourses"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline">Interactive Minicourses</span>
                </Link>
              </div>
              <div className="quadMenu colNoCenter">
                <h2>Inquiries</h2>

                <Link
                  to="/contact"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline">Contact</span>
                </Link>
                <Link
                  to="/visit"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline">Visit</span>
                </Link>
                <Link
                  to="/labschedule"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline">Lab Schedule</span>
                </Link>
                <Link
                  to="/events"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat bold"
                >
                  <span className="whiteLinkNoUnderline">Events</span>
                </Link>
              </div>
              <div className="quadMenu colNoCenter">
                <h2>Related Links</h2>

                <a
                  href="https://maddcenter.uchicago.edu/"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="montserrat linkMarginBottom bold"
                >
                  <span className="whiteLinkNoUnderline">M.A.D.D. Center</span>
                </a>
                <a
                  href="https://computerscience.uchicago.edu"
                  onClick={() => this.setState({ menuIsOpen: false })}
                  className="montserrat linkMarginBottom bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="whiteLinkNoUnderline">UChicago CS</span>
                </a>
              </div>
            </div>
          </div>
        </div>        
    );
  }
}

export default Navigation;
